<template>
  <div class="mt-1">
    <b-card>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <b-row class="mb-1">
          <b-col cols="12" md="5" class="d-flex align-items-center justify-content-start mb-1 mb-md-0"> </b-col>
          <b-col cols="12" md="7">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="addTask()">
                <span class="text-nowrap">Add Task</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-table :outlined="true" :fields="fields" responsive="sm" class="table table-sm" :items="filteredTasks">
          <template #cell(title)="data">
            <div :style="{ backgroundColor: data.item.color.bgColor, color: data.item.color.textColor }" style="font-size: 1rem;" class="p-25">
              {{ data.value }}
            </div>
          </template>

          <template #cell(roles)="data">
            <span class="mr-25" v-for="(role, index) in data.value" :key="index">
              <b-badge variant="secondary"> {{ role }} </b-badge>
            </span>
          </template>

          <template #cell(actions)="data">
            <div>
              <b-button v-if="data.item.id != 1 && data.item.id != 2" variant="primary" class="mr-25 p-50" @click="editTask(data.item.id)">
                <i class="fa-solid fa-pen-to-square mr-25"></i>
                <span> Edit</span>
              </b-button>

              <b-button v-if="data.item.id != 1 && data.item.id != 2" variant="primary" @click="deleteTask(data.item.id)" class="p-50">
                <i class="fa-solid fa-trash mr-25"></i>
                <span> Delete</span>
              </b-button>
            </div>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import vSelect from 'vue-select';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
  },

  setup() {
    const TASK_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(TASK_APP_STORE_MODULE_NAME)) store.registerModule(TASK_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TASK_APP_STORE_MODULE_NAME)) store.unregisterModule(TASK_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      tasks: [],
      filter: null,
      loading: true,
      fields: [
        { key: 'title', sortable: true, class: 'text-left', thStyle: 'width:40%' },
        { key: 'roles', sortable: true, class: 'text-center', thStyle: 'width:40%' },
        { key: 'actions', sortable: false, class: 'text-right', thStyle: 'width:20%' },
      ],
      tempName: null,
      showAllAdditionals: false,
    };
  },

  watch: {},

  methods: {
    getTasks() {
      this.loading = true;
      store
        .dispatch('settings/getAllTasks')
        .then((res) => {
          this.tasks = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$router.push({ name: 'settings' });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    editTask(id) {
      this.$router.push({ name: 'settings-task-edit', params: { id: id } });
    },

    deleteTask(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this task?', {
          size: 'sm',
          title: 'Confirm Deletion',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            const task = this.tasks.find((met) => met.id === id);

            if (task) {
              store
                .dispatch('settings/deleteTask', task)
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Task Delete Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  this.getTasks();
                })
                .catch((error) => {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'An error occurred',
                      text: 'Please try again later or contact support.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  });
                });
            }
          }
        });
    },

    activeTask(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to activate this task?', {
          size: 'sm',
          title: 'Confirm Activation',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            const task = this.tasks.find((met) => met.id === id);

            if (task) {
              store
                .dispatch('settings/activeTask', task)
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Task Activate Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  this.getTasks();
                })
                .catch((error) => {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'An error occurred',
                      text: 'Please try again later or contact support.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  });
                });
            }
          }
        });
    },

    addTask() {
      this.$router.push({ name: 'settings-task-add' });
    },
  },

  created() {
    this.getTasks();
  },

  computed: {
    filteredTasks() {
      if (!this.filter) {
        return this.tasks;
      } else {
        this.tasks = this.tasks.filter((met) => met.id !== undefined);

        this.tasks.forEach((met) => {
          met.editable = false;
        });

        const searchTerm = this.filter.toLowerCase();
        return this.tasks.filter((met) => met.code.toLowerCase().includes(searchTerm));
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
